// @import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');
@import "styles/colors";

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

#root {
  height: unset;
}

* {
  box-sizing: border-box;
}

body {
  background: #1f2933 !important;
  min-height: 100vh;
  height: max-content;
  display: flex;
  font-weight: 400;
  font-family: TelegrafRegular;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #fff;
  -webkit-text-fill-color: #fff !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error {
  color: #e73d3b;
  font-size: smaller;
  display: block;
}

.warningerror {
  color: #fff;
  font-size: smaller;
  display: block;
}

.apierror {
  color: #e73d3b;
  font-size: 15px;
  display: block;
  text-align: center;
}

.form-control {
  box-shadow: none !important;
}

.Toastify__toast,
.Toastify__toast--error {
  background: #fff !important;
  color: #004848;
  border: 1px solid aliceblue;
}

.Toastify__toast--error {
  background: #fff !important;
  color: #004848;
  border: 1px solid aliceblue;
}

.Toastify__toast--success {
  background: #fff !important;
  color: #004848;
  border: 1px solid aliceblue;
}

.Toastify__close-button {
  color: #004848 !important;
}

.Toastify__progress-bar {
  background: #004848 !important;
}

.trackRevisionModalSingle {
  position: absolute;
  inset: 15%;
  /* border: 1px solid rgb(204, 204, 204); */
  // overflow: auto;
  outline: none;
  // padding: 20px;
  height: fit-content;
  width: 528px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.trackRevisionModalMultiple {
  position: absolute;
  inset: 15%;
  /* border: 1px solid rgb(204, 204, 204); */
  // overflow: auto;
  outline: none;
  // padding: 20px;
  height: fit-content;
  width: 1050px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.trackScoutingModal {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 650px;
  width: 876px;
  transform: translate(-50%, -50%);
  background-color: $grey-800; /* For browsers that do not support gradients */
  border-radius: 8px;
  outline: none;
}

.subscriptionModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $grey-800; /* For browsers that do not support gradients */
  border-radius: 8px;
  outline: none;
  max-height: 90%;
 
}

.overlaytrackrevision {
  // background: #525252 0% 0% no-repeat padding-box;
  position: fixed;
  inset: 0px;
  background-color: rgba(82, 82, 82, 0.75);
}

.closemodal {
  background: $grey-800;
  width: 36px;
  height: 36px;
  position: absolute;
  right: -10%;
  top: -12%;
  /* opacity: 0.5; */
  font-size: xx-large;
  color: #fff;
  z-index: 1;
  border: 0;
  border-radius: 1.5vw;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
  }
}

.closemodalAgency {
  background: $grey-800;
  width: 36px;
  height: 36px;
  position: absolute;
  right: -5%;
  top: -4%;
  font-size: xx-large;
  color: #fff;
  z-index: 1;
  border: 0;
  border-radius: 1.5vw;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
  }
}

.closeModalMultiple {
  background: $grey-800;
  width: 36px;
  height: 36px;
  position: absolute;
  right: -5%;
  top: -6%;
  /* opacity: 0.5; */
  font-size: xx-large;
  color: #fff;
  z-index: 1;
  border: 0;
  border-radius: 1.5vw;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
  }
}

.copyrighttext {
  font-family: "TelegrafRegular";
  font-size: 14px;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: -webkit-max-content;
  width: max-content;
  margin: 15px 0;
  color: #fff;
  position: absolute;
}

@media screen and (max-width: 480px) {
  .copyrighttext {
    font-family: "TelegrafRegular";
    font-size: 14px;
    top: 8%;
    margin: 20px 0;
    padding: 20px 0;
    text-align: -webkit-center;
    color: #fff;
    position: relative;
  }
}

.track_revision_split_view {
  display: flex;
  position: absolute;
  right: 0%;
  top: -8%;
  column-gap: 4px;
  font-family: "ClashGrotesk-Variable";
  font-size: 12px;
  color: $grey-200;
  cursor: pointer;

  .track_revision_split_view_span_regular {
    font-weight: normal;
  }

  .track_revision_split_view_span_active {
    font-weight: 600;
  }
}

.ReactModalPortal {
  z-index: 100;
}

.track_revision_right_chevron {
  width: 48px !important;
  height: 48px !important;
  border-radius: 32px !important;
  background: $grey-800;
  right: -60px !important;
  top: 300px !important;
}

.track_revision_left_chevron {
  width: 48px !important;
  height: 48px !important;
  border-radius: 32px !important;
  background: $grey-800;
  left: -60px !important;
  top: 300px !important;
}

.track_revision_reject {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  div {
    cursor: pointer;
    display: flex;
    column-gap: 5px;
    justify-content: center;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
    }

    p {
      margin: unset;
      font-family: "ClashGrotesk-Variable";
      font-size: 12px;
      line-height: 15px;
      color: $grey-400;
    }
  }
}

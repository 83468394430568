@import "../../styles/utilities.scss";
@import "../../styles/colors.scss";

.postTrackEp {
  width: 100%;
  padding: 1% 5%;
  height: 100vh;
  overflow-y: scroll;
  height: 100vh;
  background: $grey-900 !important;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  @include phone-portrait {
    padding-bottom: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mainheading {
    color: #ffffff;
    font-weight: 600;
    font-size: 28px;
    line-height: 35px;
    font-family: "ClashGrotesk-Variable";
  }

  .selecteduploadbox {
    border: 1px dashed $grey-400;
    width: 100%;
    height: 18vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    margin-top: 24px;

    .postatrack_uploadtrackorep {
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        color: #ffffff;
        font-weight: 600;
        font-size: 28px;
        line-height: 35px;
        font-family: "ClashGrotesk-Variable";
      }

      button {
        padding: 8px 40px;
        background: $aqua-green500;
        border-radius: 4px;
        text-align: center;
        max-width: 268px;
        border: none;

        p {
          margin: unset;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          font-family: "ClashGrotesk-Variable";
        }
      }
    }
  }

  .posttrack_inser_info {
    .posttrack_percentage_text {
      display: flex;
      justify-content: end;

      .percentagevalue {
        font-family: "ClashGrotesk-Variable";
        font-weight: 400;
        font-size: 14px;
        color: $grey-200;
      }
    }

    .trackepprogress {
      margin: 1vw 0vw;
      justify-content: center;

      .stroke {
        border-radius: 2px;
        height: 8px;
        width: 100%;
      }

      .rc-progress-line-path {
        stroke-linecap: square !important;
      }
    }

    .posttrack_info_container {
      background: $grey-800;
      border-radius: 8px;
      display: flex;
      padding: 32px;
      flex-direction: column;

      .posttrack_info_body {
        width: 100%;
        display: flex;
        column-gap: 40px;

        .posttrack_image_container {
          background: $grey-900;
          flex: 1;
          position: relative;

          .posttrack_info_body_preview_image {
            width: 100%;
            height: 80%;
            object-fit: cover;
          }

          button {
            background: $grey-600;
            border-radius: 4px;
            padding: 4px 12px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            column-gap: 8px;
            top: 90%;
            left: 50%;
            transform: translateX(-50%);
            border: unset;

            img {
              width: 20px;
              height: 18px;
            }

            p {
              font-family: "ClashGrotesk-Variable";
              font-size: 16px;
              font-weight: normal;
              color: $grey-50;
              margin: unset;
            }
          }
        }

        .posttrack_form_container {
          display: flex;
          flex: 1;
          justify-content: center;
          flex-direction: column;
          row-gap: 28px;

          .tracktitle {
            display: flex;
            flex-direction: column;
            row-gap: 8px;

            p {
              margin: unset;
              font-family: "ClashGrotesk-Variable";
              font-size: 16px;
              line-height: 20px;
              color: $grey-50;
            }

            input {
              border-radius: 4px;
              border: 1px solid $grey-500;
              outline: none;
              padding: 8px 6px;
              background: transparent;
            }

            input[type="text"] {
              margin: unset;
              font-family: "ClashGrotesk-Variable";
              font-size: 16px;
              line-height: 20px;
              color: $grey-50;
            }
          }

          .posttrack_select_row {
            display: flex;
            flex-direction: column;
            row-gap: 8px;

            p {
              margin: unset;
              font-family: "ClashGrotesk-Variable";
              font-size: 16px;
              line-height: 20px;
              color: $grey-50;
            }

            .ReactDropdownSelect {
              border-radius: 4px;
              border: 1px solid $grey-500;
              outline: none;
              padding: 8px 6px;
              background: transparent;
              cursor: pointer;
              pointer-events: all;

              .react-dropdown-select-content {
                display: flex !important;
                border: none !important;
                outline: none !important;
                width: 100% !important;
                flex-wrap: wrap !important;
                align-items: center;
                font-size: 14px;
                color: $grey-50 !important;
                font-family: "ClashGrotesk-Variable";

                .react-dropdown-select-input {
                  margin: unset;
                  font-family: "ClashGrotesk-Variable";
                  font-size: 16px;
                  line-height: 20px;
                  color: $grey-50;
                }
              }

              .react-dropdown-select-option {
                background-color: $grey-700 !important;
                border-radius: 2px !important;
                border: 1px solid #008181 !important;
                color: #fff !important;
                /* font-family: sans-serif; */
                font-size: 13px !important;
                font-weight: 600 !important;
                /* margin-bottom: 5px; */
                /* margin-right: 5px; */
                /* padding: 3px !important; */
                width: max-content !important;
                align-items: center;
                @include phone-portrait {
                  width: 67vw !important;
                }
              }

              .react-dropdown-select-option-label {
                width: max-content !important;
                color: $grey-50 !important;
                font-family: "ClashGrotesk-Variable";
              }

              .react-dropdown-select-item-selected {
                background: $grey-600 !important;
              }

              .react-dropdown-select-item {
                font-size: 14px;
                color: $grey-50 !important;
                font-family: "ClashGrotesk-Variable";

                &:hover {
                  background: $grey-600 !important;
                }
              }

              .react-dropdown-select-dropdown {
                background: $grey-700 !important;
              }
            }
          }

          .posttrack_description {
            display: flex;
            flex-direction: column;
            row-gap: 8px;

            p {
              margin: unset;
              font-family: "ClashGrotesk-Variable";
              font-size: 16px;
              line-height: 20px;
              color: $grey-50;
            }

            textarea {
              border-radius: 4px;
              border: 1px solid $grey-500;
              outline: none;
              padding: 8px 6px;
              background: transparent;
              min-height: 170px;
              position: relative;
            }

            textarea[type="text"] {
              margin: unset;
              font-family: "ClashGrotesk-Variable";
              font-size: 16px;
              line-height: 20px;
              color: $grey-50;
            }
          }
        }
      }

      .posttrack_info_container_footer {
        padding: 12px;
        border-top: 1px solid $grey-700;
        display: flex;
        justify-content: end;
        margin-top: 50px;

        button {
          background: $aqua-green500;
          border-radius: 4px;
          padding: 8px 16px;
          border: none;
          font-family: "ClashGrotesk-Variable";
        }
      }
    }
  }

  .posttrack_track_uploaded_container {
    background: $grey-800;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    margin-top: 24px;
    row-gap: 18px;
    padding: 88px;

    h1 {
      color: $grey-50;
      font-weight: 600;
      font-size: 36px;
      line-height: 48px;
      font-family: "ClashGrotesk-Variable";
    }

    span {
      color: $grey-400;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      font-family: "ClashGrotesk-Variable";
    }
    p {
      color: $grey-50;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      font-family: "ClashGrotesk-Variable";
      margin: unset;
      cursor: pointer;
    }
  }
}

.react-dropdown-select-option-label {
  width: max-content !important;
}

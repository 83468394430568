@import url("./styles/clash-grotesk.css");

body {
  margin: 0;
  font-family: "ClashGrotesk-Variable";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// iframe {
//   height: 0px !important;
//   width: 0px !important;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

@font-face {
  font-family: "TelegrafRegular";
  src: local("Poppins"),
    url("./assets/fonts/Telegraf/TelegrafRegular400.otf") format("truetype");
}

@font-face {
  font-family: "TelegrafUltraBold";
  src: local("Poppins"),
    url("./assets/fonts/Telegraf/TelegrafUltraBold800.otf") format("truetype");
}

@font-face {
  font-family: "TelegrafUltraLight";
  src: local("Poppins"),
    url("./assets/fonts/Telegraf/TelegrafUltraLight200.otf") format("truetype");
}

@font-face {
  font-family: "ClashGrotesk-Variable";
  src: local("Poppins"),
    url("./assets/fonts/Plus Jakarta Sans/PlusJakartaDisplay-Regular.otf")
      format("truetype");
}

@font-face {
  font-family: "Plus Jakarta Sans Bold";
  src: local("Poppins"),
    url("./assets/fonts/Plus Jakarta Sans/PlusJakartaText-Bold.otf")
      format("truetype");
}

@font-face {
  font-family: "TelegrafUltraBold";
  src: local("Poppins"),
    url("./assets/fonts/Telegraf/TelegrafUltraBold800.otf") format("truetype");
}

@font-face {
  font-family: "TelegrafUltraLight";
  src: local("Poppins"),
    url("./assets/fonts/Telegraf/TelegrafUltraLight200.otf") format("truetype");
}

@font-face {
  font-family: "Satoshi Variable";
  src: local("Poppins"),
    url("./assets/fonts/Satoshi Variable/Satoshi-Regular.otf")
      format("OpenType");
}

@font-face {
  font-family: "Clash Grotesk Variable";
  src: local("Poppins"),
    url("./assets/fonts/Clash Grotesk Variable/ClashGrotesk-Regular.otf")
      format("OpenType");
}

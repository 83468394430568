.plan_selection_container {
  background: #181818;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  height: 100%;
  width: 100%;
  padding: 32px 70px;

  @media screen and (max-width: 480px) {
    padding: 32px;
  }

  .header {
    display: flex;
    gap: 10px;

    .logo {
      width: 33px;
      height: 22px;
    }
  }

  h3 {
    font-family: "Satoshi Variable";
    font-style: normal;
    font-weight: 650;
    font-size: 28px;
    line-height: 36px;
    color: #fafafa;

    @media screen and (max-width: 480px) {
      line-height: 24px;
      text-align: center;
    }
  }

  p {
    margin: 0;
    font-family: "Satoshi Variable";
    font-style: normal;
    font-weight: 450;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.1px;
    color: #fafafa;

    @media screen and (max-width: 480px) {
      font-weight: 500;

      line-height: 24px;
      text-align: center;
    }
  }

  .checkout-text-title {
    @media screen and (max-width: 480px) {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 590px) {
    .plan_selection_elements_container {
      flex-direction: column;
    }
  }

  .plan_selection_elements_container {
    justify-content: center;
    display: flex;
    gap: 32px;

    .plan_selection_element_active {
      @media screen and (max-width: 480px) {
        border: 1px solid #61f2e1;
      }
    }

    .plan_selection_element {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 32px;
      background: #27272a;
      border-radius: 24px;
      cursor: pointer;
      gap: 12px;

      .plan_selection_element_header {
        display: flex;
        gap: 32px;

        @media screen and (max-width: 480px) {
          flex-direction: column-reverse;
          gap: 8px;
          width: 100%;
        }

        p {
          margin: 0;
          font-family: "Satoshi Variable";
          font-style: normal;
          font-weight: 650;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.2px;
          color: #a1a1aa;

          @media screen and (max-width: 480px) {
            font-weight: 650;
            font-size: 12px;
            line-height: 16px;
            text-align: start;
          }
        }

        .offer_box_container {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .selected_dot {
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background: #61f2e1;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
          }

          .unselected_dot {
            width: 18px;
            height: 18px;
            border-radius: 100%;
            border: 2px solid #61f2e1;
            position: relative;
          }

          .offer_box {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 4px 8px;
            gap: 4px;
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)
              ),
              #ff6333;
            border-radius: 6px;
            font-family: "Satoshi Variable";
            font-style: normal;
            font-weight: 450;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.4px;
            color: #fafafa;
            @media screen and (max-width: 480px) {
              font-weight: 500;
              font-size: 13px;
              line-height: 16px;
              width: fit-content;
            }
          }
        }
      }

      .price {
        font-family: "Satoshi Variable";
        font-style: normal;
        font-weight: 650;
        font-size: 44px;
        line-height: 52px;
        letter-spacing: -0.2px;
        color: #fafafa;

        @media screen and (max-width: 480px) {
          font-weight: 650;
          font-size: 28px;
          line-height: 36px;
        }
      }

      .small_text {
        font-family: "Satoshi Variable";
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: #71717a;
        text-align: start;
      }

      .align_flex {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        .benefit_title {
          font-family: "Satoshi Variable";
          font-style: normal;
          font-weight: 450;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.25px;
          color: #d4d4d8;
        }

        .benefit_row {
          display: flex;
          gap: 12px;
          justify-content: center;
          align-items: center;

          img {
            width: 12px;
            height: 10px;
          }

          p {
            margin: 0;
            font-family: "Satoshi Variable";
            font-style: normal;
            font-weight: 450;
            font-size: 18px;
            line-height: 28px;
            letter-spacing: 0.1px;
            color: #fafafa;
            text-align: start;
          }
        }
      }

      .cta_button {
        border: unset;
        padding: 12px 16px;
        gap: 8px;
        background: #61f2e1;
        border-radius: 80px;
        font-family: "Satoshi Variable";
        font-style: normal;
        font-weight: 650;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: #181818;
        align-self: start;
      }
    }
  }

  .good_news {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 8px;
    background: rgba(97, 242, 225, 0.2);
    border-radius: 24px;
    width: 100%;

    .header_container {
      display: flex;
      justify-content: center;
      gap: 10px;

      @media screen and (max-width: 480px) {
        flex-direction: column;
        align-items: center;
      }
      h2 {
        font-family: "Satoshi Variable";
        font-style: normal;
        font-weight: 650;
        font-size: 22px;
        line-height: 28px;
        color: #fafafa;
        margin: 0;
        @media screen and (max-width: 480px) {
          text-align: center;
        }
      }

      img {
        @media screen and (max-width: 480px) {
          width: 20px;
          height: 20px;
        }
      }
    }

    p {
      margin: 0;
      font-family: "Satoshi Variable";
      font-style: normal;
      font-weight: 450;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.25px;
      color: #d4d4d8;
    }
  }

  .faq_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 100px;

    h2 {
      font-family: "ClashGrotesk-Variable";
      font-style: normal;
      font-weight: 600;
      font-size: 49px;
      line-height: 120%;
      text-align: center;
      color: #fafafa;
    }

    .accordion_custom {
      display: flex;
      gap: 20px;
      flex-direction: column;
      width: 100%;
    }

    .custom_accordion_heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px;
      gap: 32px;
      background: #27272a;
      border-radius: 24px;
      font-family: "Clash Grotesk Variable";
      font-style: normal;
      font-weight: 450;
      font-size: 22px;
      line-height: 28px;
      color: #fafafa;
    }

    .no_border_radius {
      border-radius: 24px 24px 0px 0px;
    }

    .custom_accordion_item_panel {
      background: #27272a;

      .custom_accordion_item_text {
        font-family: "Satoshi Variable";
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: #cbd2d9;
        @media screen and (max-width: 480px) {
          text-align: left;
        }
      }

      border-radius: 0px 0px 24px 24px;
      padding: 24px;
    }
  }
}

@import "../../styles/utilities.scss";
@import "../../styles/colors.scss";

.component_addButton {
  border: 1.5px solid $aqua-green500;
  display: flex;
  align-items: center;
  background: transparent;
  border-radius: 16px;
  height: 32px;
  min-width: 150px;
  padding: 8px;
  cursor: pointer;
  flex-wrap: nowrap;

  span {
    color: $aqua-green500;
    font-family: "ClashGrotesk-Variable";
    font-size: 14px;
  }

  &:hover {
    background: $aqua-green500 !important;

    span {
      color: $grey-900 !important;
    }
  }

  :nth-child(1) {
    margin-right: 8px;
  }
}

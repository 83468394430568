//error

$error-red: #FF1500;
//neutral
$grey-50: #F5F7FA;
$grey-100: #E4E7EB;
$grey-200: #CBD2D9;
$grey-300: #9AA5B0;
$grey-400: #7B8793;
$grey-500: #616E7B;
$grey-600: #52606C;
$grey-700: #3E4C58;
$grey-800: #323F4A;
$grey-900: #1F2933;

// Primary Accent
$aqua-green50: #B2FFEC;
$aqua-green300: #7FFEDF;
$aqua-green500: #33FECC;
$aqua-green700: #00D49F;
$aqua-green900: #0F9977;

// Seconday Accent

$purple50: #CBA6FF;
$purple300: #8833FF;
$purple500: #6A00FF;
$purple700: #5500CC;
$purple900: #4A00B2;




@import "../../styles/colors.scss";
@import url("https://api.fontshare.com/css?f[]=clash-display@200,300,400,500,600,700&display=swap");

.carousel-slider {
  overflow: unset !important;
}

.control-dots {
  bottom: -50px !important;

  .dot {
    background-color: #71717a !important;
    box-shadow: none !important;
  }

  .selected {
    background-color: #fafafa !important;
  }
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #ffffff !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #ffffff !important;
}

.white-arrows .carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff !important;
}

.white-arrows .carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff !important;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  top: 100% !important;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}

.plan_selection_container {
  height: auto !important;
  width: auto !important;
  padding: 128px 70px !important;

  .plan_selection_elements_container {
    justify-content: center;
  }

  .button {
    p {
      font-family: "ClashGrotesk-Variable" !important;
      font-weight: 560;
    }
  }

  .top-wrapper {
    display: flex;
    flex-direction: row;
    gap: 32px;

    .card-wrapper {
      background-color: #27272a;
      border-radius: 28px;
      padding: 40px 32px;
      flex: 1;

      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 15px;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.slider {
  position: relative;
}
.slider::before {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  width: 10000%;
  height: 100%;
}

p {
  letter-spacing: 0.4px;
}

.wrapper {
  color: #e4e4e7;
  font-family: "ClashGrotesk-Variable";
  height: 100vh;

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $aqua-green500;
    padding: 8px 24px;
    border-radius: 30px;
    cursor: pointer;
    z-index: 3;

    p {
      font-weight: 560;
      font-size: 19px;
      color: $grey-800;
      margin: unset;
    }

    &:hover {
      background-color: $aqua-green700;
    }

    &:active {
      background-color: $aqua-green900;
    }
  }
}

.landing {
  background-image: url("../../assets/images/landing-page-background2.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  position: relative;

  .overlay {
    padding: 32px;
    min-height: 100vh;
    background-image: linear-gradient(180deg, #19191945 0%, #191919 100%);
  }

  .heading {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    z-index: 3;

    .spacer {
      flex: 1;
    }

    .log-in {
      font-size: 19px;
      line-height: 20px;
      color: #fafafa;
    }

    img {
      width: 120px;
      height: 28px;
    }
  }

  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 160px 0 120px 0;
  }

  .logos-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .bottom-wrapper {
    justify-self: flex-end;
  }

  h2 {
    font-size: 72px;
    line-height: 84px;
    margin-bottom: 16px;
    font-weight: 500;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 40px;
    font-weight: 400;
  }

  h4 {
    text-align: center;
    font-size: 28px;
    line-height: 36px;
  }
}

.accelerate-career {
  background-color: #181818;
  padding: 128px 80px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  position: relative;
  overflow: hidden;

  .brand-identity {
    position: absolute;
    top: 10%;
    left: 7%;
    z-index: 1;
  }

  .left-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
    z-index: 2;
  }

  h3 {
    font-size: 44px;
    line-height: 52px;
    color: #fafafa;
    font-weight: 530;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    color: #fafafa;
  }

  video {
    max-width: 60%;
    max-height: 60%;
  }

  img {
    max-width: 90%;
    max-height: 90%;
  }
}

.courses-library {
  background-color: #181818;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  padding: 128px 0;
  position: relative;
  overflow: hidden;

  .instructions {
    text-align: center;
    z-index: 2;
  }

  .brand-identity-one {
    position: absolute;
    top: -4%;
    left: 2%;
    z-index: 0;
  }

  .brand-identity-two {
    position: absolute;
    bottom: 10%;
    right: 2%;
    z-index: 0;
  }

  .single-card {
    max-width: 300px;
    max-height: 400px;
    margin: auto;
    border-radius: 24px;
    overflow: hidden;
  }

  .bottom-card {
    max-width: 300px;
    max-height: 300px;
    margin: 10px auto 0px auto;
    border-radius: 24px;
    overflow: hidden;
  }

  .library-page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 2;

    h2 {
      margin-top: 20px;
      margin-bottom: 0px;
    }
  }

  .library-wrapper {
    max-width: 1360px;
  }

  .white-arrows {
    max-width: 1360px;
    margin-bottom: 32px;
  }

  .positioner {
    position: relative;
  }

  .tutor-info {
    position: absolute;
    z-index: 100;
    top: 0;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;

    &:hover {
      background-color: #000000d9;

      .extra-info {
        display: block;
      }
    }

    .available-badge {
      font-size: 13px;
      line-height: 16px;
      color: #f4f4f5;
      background-color: #3f3f46;
      border-radius: 6px;
      padding: 4px 8px;
    }

    .upcoming-badge {
      font-size: 13px;
      line-height: 16px;
      color: #f4f4f5;
      background-color: #ff6333;
      border-radius: 6px;
      padding: 4px 8px;
    }

    .tutor-name {
      font-size: 24px;
      line-height: 28px;
      text-align: left;
    }

    .tutor-category {
      font-size: 13px;
      line-height: 18px;
    }

    .extra-info {
      display: none;
      margin-top: 16px;
      font-size: 13px;
      line-height: 16px;
    }

    .spacer {
      flex: 1;
    }
  }

  .first-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    gap: 16px;
    max-height: 650px;
    align-items: stretch;
    z-index: 2;

    .image-wrapper {
      overflow: hidden;
      flex: 1;
      border-radius: 24px;

      img {
        height: 100% !important;
        object-fit: cover !important;
      }
    }

    .small-column-wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      flex: 1;

      .small-image-wrapper {
        border-radius: 24px;
        flex: 1;
        overflow: hidden;

        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .second-row {
    display: flex;
    flex-direction: row;
    max-height: 300px;
    gap: 16px;
    z-index: 2;

    .first-element {
      width: 40% !important;
    }

    .image-wrapper {
      border-radius: 24px;
      overflow: hidden;
      z-index: 2;

      img {
        height: 100% !important;
        object-fit: cover !important;
      }
    }

    .stretch {
      flex: 1;
    }

    img {
      object-fit: fill;
      z-index: 2;
    }
  }

  h3 {
    font-size: 44px;
    line-height: 52px;
    color: #fafafa;
    z-index: 2;
    font-weight: 530;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    color: #fafafa;
    z-index: 2;
  }
}

.target-audience {
  background-color: #fafafa;
  padding: 128px 70px;

  .target-audience-tiles-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    margin-top: 64px;

    .target-audience-tile {
      padding: 40px 74px;
      background-color: #f4f4f5;
      border-radius: 28px;
      flex: 1;

      .target-audience-tile-title {
        font-size: 28px;
        line-height: 36px;
        text-align: center;
        color: #181818;
        margin-bottom: 4px;
        font-weight: 530;
      }

      .target-audience-tile-text {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.1px;
        color: #52525b;
        text-align: center;
      }
    }
  }

  h3 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: -0.5px;
    text-align: center;
    color: #181818;
    margin-bottom: 12px;
    font-weight: 530;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.1px;
    text-align: center;
    color: #181818;
    max-width: 750px;
    margin: auto;
  }
}

.benefits {
  background-color: #e4e4e7;
  padding: 144px 40px;

  position: relative;
  overflow: hidden;

  .brand-identity-one {
    position: absolute;
    top: 10%;
    left: 0;
    z-index: 1;
  }

  .brand-identity-two {
    position: absolute;
    bottom: -15%;
    right: 8%;
    z-index: 1;
  }

  .benefits-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin-bottom: 64px;
    z-index: 3;
  }

  .benefits-tiles-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;

    height: 100%;
    z-index: 3;
    margin: 0 10px;

    .benefits-tile {
      padding: 24px 48px;
      background-color: #f4f4f5;
      border-radius: 28px;
      flex: 1;
      z-index: 3;

      img {
        width: 20px;
        height: 20px;
        z-index: 3;
      }

      display: flex;
      flex-direction: column;
      align-items: center;

      .benefits-tile-title {
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        color: #181818;
        margin-bottom: 8px;
        z-index: 3;
        font-weight: 560;
      }

      .benefits-tile-subtitle {
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #181818;
        letter-spacing: 0.5px;
        margin-bottom: 20px;
        z-index: 3;
      }

      .benefits-tile-text {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.1px;
        color: #52525b;
        text-align: center;
        z-index: 3;
      }
    }
  }

  h3 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: -0.5px;
    text-align: center;
    color: #181818;
    margin-bottom: 12px;
    z-index: 3;
    font-weight: 530;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.1px;
    text-align: center;
    color: #181818;
    max-width: 750px;
    margin: auto;
    z-index: 3;
  }
}

.partners {
  background-color: #fafafa;
  padding: 128px 180px;

  .carousel-wrapper {
    max-width: 1000px;
    margin: auto;
    margin-top: 64px;
  }

  .logo-carousel-wrapper {
    display: flex;
    flex-direction: row;
    gap: 4px;

    .logo-carousel-item-wrapper {
      flex: 1;
    }
  }

  .partners-tiles-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    margin-top: 64px;

    img {
      width: 20px;
      height: 20px;
    }

    .partners-tile {
      padding: 40px 74px;
      background-color: #f4f4f5;
      border-radius: 28px;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      .partners-tile-title {
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        color: #181818;
        margin-bottom: 8px;
      }

      .partners-tile-subtitle {
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #181818;
        letter-spacing: 0.5px;
        margin-bottom: 20px;
      }

      .partners-tile-text {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.1px;
        color: #52525b;
        text-align: center;
      }
    }
  }

  h3 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: -0.5px;
    text-align: center;
    color: #181818;
    margin-bottom: 12px;
  }
}

.news {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 64px;
  padding: 144px 70px;

  background-color: #181818;

  .left-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
  }

  .right-wrapper {
    flex: 1;
  }

  h3 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: -0.5px;
    color: #f5f7fa;
    margin-bottom: 12px;
  }

  h4 {
    font-size: 36px;
    line-height: 52px;
    letter-spacing: -0.5px;
    color: #f5f7fa;
    margin-bottom: 12px;
    font-weight: 530;
  }

  .label {
    font-size: 16px;
    line-height: 20px;
    background-color: #71717a;
    border-radius: 6px;
    color: #181818;
    padding: 5px 8px;
    margin-left: 20px;
    vertical-align: super;
  }

  .grey {
    color: #52525b;
  }
}

.overview {
  background-color: #181818;
  padding: 144px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;

  position: relative;
  overflow: hidden;

  .brand-identity-one {
    position: absolute;
    top: 3%;
    left: 3%;
    z-index: 1;
  }

  .brand-identity-two {
    position: absolute;
    top: 25%;
    right: 0%;
    z-index: 1;
  }

  .breadcrumbs {
    color: #71717a;

    .active {
      color: #61f2e1;
    }

    span {
      cursor: pointer;
      margin: 4px;
    }
  }

  .mac {
    z-index: 2;
  }

  h3 {
    font-size: 44px;
    line-height: 52px;
    color: #fafafa;
    text-align: center;
    z-index: 2;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    color: #fafafa;
    text-align: center;
    z-index: 2;
  }

  img {
    max-width: 100%;
  }
}

.feedback {
  background: #181818;
  margin-top: 50px;

  .carousel {
    .slider-wrapper.axis-horizontal {
      .slider {
        .slide {
          display: flex;
          align-items: center;
          padding: 10px;
          justify-content: center;
        }
      }
    }
  }
  .display-type {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    height: 100%;
  }

  .feedback-tiles-wrapper {
    margin-top: 64px;

    .carousel-tile {
      max-width: 550px;
      margin: 0 10px;
      height: 100%;
    }

    .feedback-tile {
      padding: 32px;
      background-color: #f4f4f5;
      border-radius: 28px;
      flex: 1;
      height: fit-content;

      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 110px;
      }

      .feedback-tile-name {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #181818;
        margin-bottom: 16px;
      }

      .feedback-tile-text {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: #181818;
        text-align: center;
      }

      .feedback-tile-date {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.4px;
        color: #52525b;
        text-align: center;
      }

      .bottom-spacer {
        flex: 1;
      }

      .feedback-tile-caption {
        font-size: 10px;
        line-height: 11.2px;
        color: #52525b;
        text-align: center;

        span {
          color: #15d67c;
        }
      }
    }
  }

  h3 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: -0.5px;
    text-align: center;

    margin: auto;
    color: #181818;
  }

  img {
    margin-bottom: 16px;
  }
}

.mission {
  background-color: #3f3f46;
  padding: 128px 180px;

  .mission-wrapper {
    background-color: #f4f4f5;
    border-radius: 24px;
    padding: 40px 64px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 160px;
  }

  .left-wrapper {
    flex: 3;
  }

  .mission-tiles-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    // align-items: center;
    flex: 2;

    .mission-tile {
      background-color: #e4e4e7;
      border-radius: 24px;
      padding: 24px;

      .mission-tile-title {
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        color: #181818;
      }

      .mission-tile-text {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: #181818;
        text-align: center;
        margin: 0;
      }
    }
  }

  h3 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: -0.5px;
    color: #181818;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.1px;
    color: #181818;
  }
}

.navigate {
  background-color: #181818;
  padding: 144px 80px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;

  position: relative;
  overflow: hidden;

  .brand-identity-one {
    position: absolute;
    top: 3%;
    left: 3%;
    z-index: 1;
  }

  .mac {
    z-index: 2;
    max-width: 80%;
    max-height: 80%;
  }

  .brand-identity-two {
    position: absolute;
    top: 25%;
    right: 0%;
    z-index: 1;
  }

  .right-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 2;
  }

  h3 {
    font-size: 44px;
    line-height: 52px;
    color: #fafafa;
    font-weight: 530;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    color: #fafafa;
  }
}

.faq {
  background-color: #181818;
  padding: 128px 290px;

  h3 {
    font-size: 44px;
    line-height: 52px;
    letter-spacing: -0.5px;
    text-align: center;
    margin-bottom: 64px;
    font-weight: 530;
  }
}

.faq-item {
  background-color: #27272a;
  border-radius: 24px;
  padding: 24px;
  margin-bottom: 20px;

  .faq-heading {
    color: #fafafa;
    font-size: 22px;
    line-height: 28px;

    .accordion__button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  .faq-panel {
    margin-top: 32px;
  }

  .faq-content-line {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #cbd2d9;
  }
}

.bottom-cta {
  background-color: #181818;
  padding: 64px 128px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .cta {
    font-size: 72px;
    line-height: 84px;
    letter-spacing: -1px;
    text-align: center;
    color: #f5f7fa;
  }

  .cta--grey {
    font-size: 72px;
    line-height: 84px;
    letter-spacing: -1px;
    text-align: center;
    color: #71717a;
    margin-bottom: 32px;
  }
}

.footer {
  background-color: #27272a;
  padding: 64px 70px 164px 70px;

  .top-section {
    border-bottom: 1px solid #3f3f46;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 20px;
      margin-bottom: 30px;

      p {
        max-width: 240px;
      }

      img {
        width: 120px;
        height: 28px;
      }
    }

    .spacer {
      flex: 1;
    }

    .right {
    }
  }

  .footer-text {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #71717a;
  }

  .footer-link {
    display: block;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #71717a;
    margin-bottom: 20px;
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $aqua-green500;
  padding: 8px 24px;
  border-radius: 30px;
  cursor: pointer;
  z-index: 3;

  p {
    font-weight: 560;
    font-size: 19px;
    color: $grey-800;
    margin: unset;
  }

  &:hover {
    background-color: $aqua-green700;
  }

  &:active {
    background-color: $aqua-green900;
  }
}

.button--white {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  padding: 8px 24px;
  border-radius: 30px;
  cursor: pointer;

  p {
    font-weight: 560;
    font-size: 19px;
    color: $grey-800;
    margin: unset;
  }
}

.bold {
  font-weight: 600;
  margin: 0;
}

.top-border {
  border-top: 1px solid #3f3f46;
  padding-top: 32px;
}

.cta-banner {
  padding: 20px;
  width: 100%;
  background-color: #181818;
  position: fixed;
  bottom: 0px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1000000;

  .title {
    // max-width: 600px;
  }

  .countdown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    // gap: 5px;
    // width: 300px;

    .countdown-number {
      text-align: center;
    }
  }

  h3 {
    font-size: 48px;
    line-height: 56px;
    font-weight: 530;

    span {
      color: #ff6333;
    }
  }

  .count-wrapper {
    font-size: 48px;
    line-height: 56px;
  }

  .timer-text {
    font-size: 13px;
    line-height: 20px;
    color: #fafafa;
    text-align: center;
    padding: 0;
    min-width: 49px;
  }

  .timer-number {
    // max-width: 20px;
  }

  .button {
    background-color: #ff6333;

    p {
      white-space: nowrap;
    }
  }
}

@media only screen and (max-width: 1080px) {
  .logos-wrapper img {
    width: 200px;
  }

  .accelerate-career {
    flex-direction: column;

    .left-wrapper {
      align-items: center;
      text-align: center;
    }

    video {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .target-audience-tile {
    padding: 16px 32px !important;
  }

  .partners-tile {
    padding: 16px 32px !important;
  }

  .mission {
    padding: 40px 16px;
  }

  .faq {
    padding: 40px 16px;

    h3 {
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .logos-wrapper img {
    width: 150px;
  }

  .mission-wrapper {
    flex-direction: column !important;
    gap: 32px !important;
    text-align: center;
    align-items: unset !important;

    .mission-tiles-wrapper {
    }
  }

  .navigate {
    flex-direction: column;

    .right-wrapper {
      order: -1;
      align-items: center;

      h3 {
        text-align: center;
      }

      p {
        text-align: center;
      }
    }
  }

  .benefits-tiles-wrapper {
    flex-direction: column !important;
  }

  .cta-banner {
    flex-direction: column;
    text-align: center;
    padding: 10px;

    h3 {
      font-size: 22px;
      line-height: 26px;
    }

    .title {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .plan_selection_container {
    .top-wrapper {
      flex-direction: column;
    }
  }

  .landing {
    h2 {
      font-size: 36px;
      line-height: 40px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
    }
  }
  .center {
    align-items: center !important;
    text-align: center;
  }

  .logos-wrapper img {
    width: 100px;
  }

  .accelerate-career {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding: 40px 16px;

    .left-wrapper {
      align-items: center;
    }
  }

  .target-audience {
    padding: 40px 16px;
  }

  .target-audience-tiles-wrapper {
    flex-direction: column !important;
  }

  .benefits-tiles-wrapper {
    flex-direction: column;
  }

  .partners {
    padding: 40px 16px 80px 16px;
  }

  .news {
    padding: 40px 16px;
    flex-direction: column;
    gap: 16px;

    .left-wrapper {
      align-items: center;
      text-align: center;
    }
  }

  .overview {
    padding: 40px 16px;
  }

  .courses-library {
    padding: 40px 16px;
  }

  .benefits {
    padding: 40px 16px 60px 16px;
  }

  .plan_selection_container {
    padding: 40px 16px 60px 16px !important;
  }

  .feedback {
    padding: 40px 16px 60px 16px;
  }

  .partners-tiles-wrapper {
    flex-direction: column !important;
  }

  .navigate {
    padding: 40px 16px 70px 16px;
  }

  .faq {
    h3 {
      margin-bottom: 20px;
    }
  }

  .bottom-cta {
    padding: 40px 16px;
  }

  .cta {
    font-size: 32px !important;
    line-height: 44px !important;
  }

  .cta--grey {
    font-size: 32px !important;
    line-height: 44px !important;
  }

  .footer {
    padding: 40px 16px;
  }
}

@media only screen and (max-width: 480px) {
  .heading > .button {
    display: none;
  }

  .landing {
    h4 {
      font-size: 19px;
      line-height: 24px;
    }
  }

  .accelerate-career {
    gap: 32px;
    h3 {
      font-size: 22px;
      line-height: 28px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .target-audience {
    gap: 32px;
    h3 {
      font-size: 22px;
      line-height: 28px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
    }

    .target-audience-tiles-wrapper {
      margin-top: 16px;
    }
    .target-audience-tile {
      .target-audience-tile-title {
        font-size: 19px !important;
        line-height: 24px !important;
      }

      .target-audience-tile-text {
        font-size: 13px !important;
        line-height: 16px !important;
      }
    }
  }

  .courses-library {
    h3 {
      font-size: 22px;
      line-height: 28px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
    }
  }

  .benefits {
    .benefits-top {
      gap: 16px !important;
      margin-bottom: 16px;
    }
    h3 {
      font-size: 22px;
      line-height: 28px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
    }

    .benefits-tile {
      padding: 20px 8px !important;
    }

    .benefits-tile-title {
      font-size: 19px !important;
      line-height: 24px !important;
    }

    .benefits-tile-text {
      font-size: 16px !important;
      line-height: 20px !important;
    }
  }

  .news {
    .right-wrapper {
      width: 100%;
    }
    h3 {
      font-size: 22px;
      line-height: 28px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .partners {
    h3 {
      font-size: 22px;
      line-height: 28px;
    }

    .partners-tiles-wrapper {
      margin-top: 16px;
    }

    .partners-tile {
      padding: 20px 8px !important;
    }

    .partners-tile-title {
      font-size: 19px !important;
      line-height: 24px !important;
    }

    .partners-tile-text {
      font-size: 16px !important;
      line-height: 20px !important;
    }
  }

  .overview {
    gap: 16px;
    h3 {
      font-size: 22px;
      line-height: 28px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
    }
  }

  .feedback {
    .feedback-tiles-wrapper {
      margin-top: 16px !important;
    }

    .feedback-tile {
      padding: 20px !important;
    }

    .feedback-tile-text {
      font-size: 13px !important;
      line-height: 16px !important;
    }
    h3 {
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 16px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
    }
  }

  .mission {
    .mission-wrapper {
      padding: 16px;
    }

    .mission-tile-title {
      font-size: 22px !important;
      line-height: 28px !important;
    }

    .mission-tile-text {
      font-size: 16px !important;
      line-height: 20px !important;
    }

    h3 {
      font-size: 22px !important;
      line-height: 28px !important;
    }

    p {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
    }
  }

  .faq-item {
    padding: 16px;
  }
  .faq-heading {
  }

  .faq-panel {
    margin-top: 8px !important;
  }

  .top-border {
    padding-top: 8px;
  }

  .navigate {
    h3 {
      font-size: 22px;
      line-height: 28px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
    }
  }

  .heading {
    gap: 4px !important;
  }

  .landing {
    .center {
      margin: 36px 0 50px 0;
    }
  }

  .logos-wrapper img {
    width: 75px;
  }

  .cta {
    font-size: 22px !important;
    line-height: 28px !important;
  }

  .cta--grey {
    font-size: 22px !important;
    line-height: 28px !important;
  }

  .footer .top-section {
    flex-direction: column !important;

    p {
      max-width: none !important;
    }
  }
}

@media only screen and (max-width: 380px) {
  .logos-wrapper img {
    width: 50px;
  }
}
